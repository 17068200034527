<script setup lang="ts">
    import { onMounted } from 'vue';
    import TimeActivityCollection from '~~/app/planning/collections/TimeActivityCollection';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import TimeActivityEndpoint from '~~/app/planning/endpoints/TimeActivityEndpoint';
    import TimeActivity from '~~/app/planning/models/TimeActivity';
    import { reactiveCollection } from '~/utils/reactiveModels';

    const timeActivities: Ref<TimeActivityCollection | null> = reactiveCollection<TimeActivity, TimeActivityCollection>(null);

    onMounted(async () => {
        const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES) as TimeActivityEndpoint;
        const response = await endpoint.index({ include: 'user', filter: { status: 'in_progress' } });

        if (response.error) {
            console.error(response);
            return;
        }

        timeActivities.value = response.data;
        timeActivities.value?.setItemShouldBeInCollectionCallback((timeActivity: TimeActivity) => timeActivity.endsAt == null);
    });
</script>

<template>
    <div>
        <template v-if="timeActivities?.isEmpty()">
            <div class="flex items-center px-4 py-2 space-x-4 bg-neutral-100 rounded whitespace-nowrap">
                <i class="text-gray-400 far fa-exclamation-triangle"></i>
                <p class="text-xs text-gray-600">{{ $t('planning.no_current_time_activity') }}</p>
            </div>
        </template>
        <template v-if="timeActivities?.isNotEmpty()">
            <div
                class="flex flex-col space-y-1 max-h-[150px] overflow-y-auto nice-scrollbar"
                :class="{ 'mb-4': timeActivities?.isNotEmpty() }"
            >
                <div
                    v-for="timeActivity in timeActivities.get()"
                    :key="timeActivity.getInternalId()"
                    class="flex items-center w-full px-2 py-1 space-x-2 transition-all rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200"
                    @click="timeActivity.preview()"
                    @contextmenu="timeActivity.openContextMenu($event)"
                >
                    <div
                        :data-tooltip="timeActivity?.user ? timeActivity.user.name : null"
                        class="flex-shrink-0 w-6 h-6 bg-neutral-300 bg-center bg-cover rounded-full"
                        :style="timeActivity.user ? `background-image: url(${timeActivity.user.avatar})` : ''"
                    ></div>
                    <div
                        class="flex-1 mr-2"
                        style="min-width: 0px"
                    >
                        <p class="text-sm text-gray-600 truncate">
                            {{ timeActivity.title }}
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <PlanningTimeActivityItemTimer
                            :time-activity="timeActivity"
                            format="HH:mm"
                            timer-class="text-sm font-semibold"
                            timer-days-class="hidden"
                            :display-tooltip="false"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
