<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { TENANT_SUBSCRIPTIONL_STATUS } from '~/app/auth/enums/TenantSubscriptionStatus';

    const { tenant } = storeToRefs(useAuthStore());

    const props = defineProps({
  collapsed: { type: Boolean }
});
</script>

<template>
    <NuxtLink
        v-if="tenant?.subscriptionStatus === TENANT_SUBSCRIPTIONL_STATUS.IN_TRIAL"
        to="/settings/subscription/trial'"
        :class="[
            `${$theme('button.style.green_sm3')} cursor-pointer w-full flex items-center space-x-2 whitespace-no-wrap`,
            collapsed ? 'justify-center' : 'justify-start whitespace-no-wrap truncate',
        ]"
    >
        <i class="far fa-check"></i>
        <span
            v-if="!collapsed"
            class="hidden lg:inline-block"
        >
            {{ $t('actions.activate_subscription') }}
        </span>
    </NuxtLink>
</template>
