<script setup lang="ts">
    import FullCalendar from '@fullcalendar/vue3';

    const props = defineProps({
  calendarOptions: null,
  displayEventTime: { type: Boolean, default: true },
  getModelFromEvent: { type: Function }
});

    const calendar = ref(null);

    defineExpose({
        calendar,
    });
</script>

<template>
    <div class="h-full full-calendar-custom-style-container">
        <FullCalendar
            ref="calendar"
            :options="calendarOptions"
        >
            <template #eventContent="args">
                <PlanningPlanningItemFullCalendarEventHtml
                    v-if="getModelFromEvent(args.event)"
                    :planning-item="getModelFromEvent(args.event)"
                    :display-time="displayEventTime"
                    :key="getModelFromEvent(args.event).model ? getModelFromEvent(args.event).model.getInternalId() : args.event.id"
                />
            </template>
        </FullCalendar>
    </div>
</template>

<style lang="scss">
    .full-calendar-custom-style-container {
        .fc-theme-standard {
            --fc-button-bg-color: #f3f4f6;
            --fc-button-border-color: #f3f4f6;
            --fc-button-text-color: #4b5563;
            --fc-button-hover-bg-color: #e5e7eb;
            --fc-button-hover-border-color: #e5e7eb;
            --fc-button-hover-text-color: #4b5563;
            --fc-button-active-bg-color: #e5e7eb;
            --fc-button-active-border-color: #e5e7eb;

            .fc-header-toolbar {
                padding: 1.5rem;
            }
            @media (min-width: 1024px) {
                .fc-header-toolbar {
                    padding: 3rem;
                }
            }

            .fc-toolbar-title {
                font-size: 18px;
                text-transform: capitalize;
            }
            .fc-toolbar-chunk {
                display: flex;
                align-items: center;
            }

            .fc-button {
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
                outline: none !important;
                font-size: 0.8rem;
                padding: 0.6rem 1.2rem;
            }
            .fc-button:focus {
                box-shadow: none !important;
            }

            .fc-prev-button,
            .fc-next-button {
                display: flex !important;
                background-color: #ffffff !important;
                transition-property: all !important;
                color: #374151 !important;
                justify-content: center !important;
                align-items: center !important;
                width: 2rem !important;
                height: 2rem !important;
                border-radius: 9999px !important;
                border-width: 1px !important;
                border-color: #e5e7eb !important;
                cursor: pointer !important;
            }
            .fc-prev-button:hover,
            .fc-next-button:hover {
                background-color: #f1f5f9 !important;
                color: #002ed8 !important;
            }
            .fc-next-button {
                margin-left: 0.5rem !important;
            }
            @media (min-width: 1024px) {
                .fc-prev-button,
                .fc-next-button {
                    width: 2.5rem !important;
                    height: 2.5rem !important;
                }
            }
            @media (min-width: 1280px) {
                .fc-prev-button,
                .fc-next-button {
                    width: 3rem !important;
                    height: 3rem !important;
                }
            }

            .fc-theme-standard td,
            .fc-theme-standard th {
                border-color: #f9fafb;
            }

            .fc-scrollgrid {
                border: none;
            }

            .fc-col-header-cell {
                padding: 10px 0;
            }
            .fc-col-header-cell-cushion {
                font-weight: 400;
            }

            .fc-timegrid-slot {
                height: 2rem;
                font-size: 15px;
                color: #4b5563;
            }
            .fc-timegrid-slot-minor {
                border: none;
            }

            .fc-v-event,
            .fc-h-event {
                border: none !important;
                box-shadow: none !important;
                background: none !important;
            }
            .fc-daygrid-dot-event:hover {
                background: none !important;
            }

            .fc-day-today {
                background: #f8fafc !important;
            }

            .fc-daygrid-day-frame {
                padding: 10px;
            }

            .fc-daygrid-day-top {
                flex-direction: row !important;

                a {
                    font-size: 16px;
                    color: #9ca3af;
                }
            }
        }

        .v-calendar-sidebar .dp__main {
            width: auto;
        }
    }
</style>
