<script setup lang="ts">
    import CalendarEvent from '~/app/planning/models/CalendarEvent';
    import PlanningItem from '~/app/planning/models/PlanningItem';
    import { hex2pastel } from '~/utils/string-helpers';

    const props = defineProps({
  planningItem: null,
  model: null,
  displayTime: { type: Boolean }
});

    const eventStyle = computed(() => {
        return `
            background-color: ${hex2pastel(props.model.eventType.color(), 94)};
            border-color: ${props.model.eventType.color()};
            color: ${props.model.eventType.color()};
        `;
    });
</script>

<template>
    <div
        :id="model.getInternalId()"
        class="relative flex flex-col w-full h-full p-4 border-l-[5px] rounded-md transition-all overflow-y-auto nice-scrollbar"
        :style="eventStyle"
        @click.prevent="model.edit()"
    >
        <div
            class="flex items-center justify-between h-auto mb-1 hide-on-sm-h"
            v-if="displayTime"
        >
            <div class="flex items-center">
                <span class="text-[14px] font-semibold mr-1"> {{ model.startsAt?.toShortTimeString() }} - {{ model.endsAt?.toShortTimeString() }} </span>
                <i
                    class="text-xs fas fa-stop-circle"
                    v-if="model.timetrackingStatus == 'in-progress'"
                ></i>
                <i
                    class="text-xs fas fa-check-circle"
                    v-if="model.timetrackingStatus == 'completed'"
                ></i>
            </div>
            <span class="text-[12px] hide-on-sm-w">
                <i class="far fa-clock"></i>
                {{ useDateDiff(model.startsAt, model.endsAt).toShortTimeString() }}
            </span>
        </div>
        <div class="">
            <div class="mb-2">
                <span class="block text-[13px] leading-tight">{{ model.displayTitle }}</span>
            </div>
        </div>
        <div
            class="flex items-center hide-on-md-h"
            v-if="model.users"
        >
            <div class="relative">
                <UsersAvatarList
                    :users="model.users"
                    size="w-[22px] h-[22px]"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .calendar-event-item--small-h .hide-on-sm-h {
        display: none;
    }
    .calendar-event-item--medium-h .hide-on-md-h {
        display: none;
    }
    .calendar-event-item--small-w .hide-on-sm-w {
        display: none;
    }
    .calendar-event-item--small-w .text-\[14px\] {
        font-size: 12px;
    }
    .calendar-event-item--small-w .text-\[13px\] {
        font-size: 11px;
    }
</style>
