<script setup lang="ts">
    import PlanningItem from '@/app/planning/models/PlanningItem';

    const props = defineProps({
  planningItem: null,
  displayTime: { type: Boolean }
});

    const CalendarEventFullCalendarEventHtml = resolveComponent('PlanningCalendarEventFullCalendarEventHtml');
    const TaskFullTaskHtml = resolveComponent('TaskTaskFullCalendarEventHtml');
    const WorksitePhaseFullCalendarEventHtml = resolveComponent('WorksiteWorksitePhaseFullCalendarEventHtml');
    const EventHtml = computed(() => {
        switch (props.planningItem.modelType) {
            case 'calendar-event':
                return CalendarEventFullCalendarEventHtml;
            case 'task':
                return TaskFullTaskHtml;
            case 'worksite-phase':
                return WorksitePhaseFullCalendarEventHtml;
        }
    });
</script>

<template>
    <component
        :is="EventHtml"
        :model="planningItem.model"
        :planning-item="planningItem"
        :display-time="displayTime"
        :key="planningItem.model.getInternalId()"
    />
</template>
