import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WorksitePhaseRelationships = {
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    tasks: ToManyRelationship<ModelType.TASKS>;
    calendarEvents: ToManyRelationship<ModelType.CALENDAR_EVENTS>;
};

type WorksitePhaseAttributes = {
    title: string;
    startsAt: string;
    endsAt: string;
};

type WorksitePhaseMeta = {};

export default class WorksitePhaseSchema extends BaseSchema<WorksitePhaseAttributes, WorksitePhaseMeta, WorksitePhaseRelationships> {
    protected type: ModelType = ModelType.WORKSITE_PHASES;
}
